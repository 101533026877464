window.realjQuery = jQuery;

jQuery(function ($) {
    $('#commentboxopenbutton').click(function (event) {
        event.preventDefault();
        $('.commentbox').slideToggle();
        $('#commentboxopenbutton').hide();
        $('#commentboxclosebutton').show();
    });

    $('#commentboxclosebutton').click(function (event) {
        event.preventDefault();
        $('.commentbox').slideToggle();
        $('#commentboxopenbutton').show();
        $('#commentboxclosebutton').hide();
    });

    $('.fullpage table').addClass("table");

    //SLICK
    setTimeout(function(){
        $('.slick').slick({
            arrows: false,
            dots: true,
            slide: 'li',
            infinite: true,
            autoplay: true,
            autoplaySpeed: 4000,
            speed: 300
        });
    },10);

    //SLICK
    setTimeout(function(){
        $('.slick3er').slick({
            arrows: true,
            speed: 300,
            slide: 'li',
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    },10);

    $('.slickmymobile').slick({
        mobileFirst: true,
        arrows: false,
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: 'unslick'
            }
        ]
    });

    //SCROLL TO TOP BUTTON
    // Der Button wird mit JavaScript erzeugt und vor dem Ende des body eingebunden.
    var back_to_top_button = ['<a href="#top" class="back-to-top">&#8963;</a>'].join("");
    $("body").append(back_to_top_button)
    // Der Button wird ausgeblendet
    $(".back-to-top").hide();
    // Funktion für das Scroll-Verhalten
    $(function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) { // Wenn 100 Pixel gescrolled wurde
                $('.back-to-top').fadeIn();
            } else {
                $('.back-to-top').fadeOut();
            }
        });

        $('.back-to-top').click(function () { // Klick auf den Button
            $('body,html').animate({
                scrollTop: 0
            }, 800);
            return false;
        });
    });


});

var youtubeinitialzied = false;
// Youtube Script Datenschutz
window.addEventListener('load',function () {
if (youtubeinitialzied) return;
youtubeinitialzied = true
    var defaults = {
        className: 'youtube-iframe-placeholder',
        color: 'white',
        backgroundColor: 'black',
        html: 'Mit dem Aufruf des Videos erklärst Du Dich einverstanden, dass Deine Daten an YouTube übermittelt werden und dass Du die <a href="https://business.stuttgart-tourist.de/datenschutz/" target="_blank">Datenschutzerklärung</a> gelesen hast.',
        button1: 'Video laden',
        button2: 'Youtube-Inhalte immer anzeigen',
        button1Classes: 'btn btn-primary',
        button2Classes: 'btn btn-primary',
        cookieName: 'youtube-show-general',
        // Seitenverhältnis des divs, z.B. 4/3 oder 16/9
        ratio: 4/3
    };

    var iFrames = document.getElementsByTagName('iframe');

    var fragments = [];
    for (var i = 0; i < iFrames.length; i++) {
        var iFrame = iFrames[i];

        var src = iFrame.src;
        var dataSrc = iFrame.dataset ? iFrame.dataset.src : '';

        var showAlways = getCookie(defaults.cookieName)==='true';

        if (
            showAlways ||
            !(isYoutube(src) || isYoutube(dataSrc))
        ) {
            showIFrame(iFrame);
        } else {
            var acknowledgeFragment = getAcknowledgeFragment(iFrame);
            fragments.push(acknowledgeFragment);
            iFrame.after(acknowledgeFragment);
            iFrame.style.display = 'none';
        }
    }

    function setCookie(cookieName, value) {
        document.cookie = cookieName + '=' + value + '; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
    }

    function getCookie(cookieName) {
        var b = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    }

    function showIFrame(iFrame) {
        iFrame.style.display = 'inherit';
        if (iFrame.dataset.src) {
            iFrame.src = iFrame.dataset.src;
        }
    }

    function getAcknowledgeFragment(iFrame) {
        var outerDiv = document.createElement('DIV');
        var innerDiv = document.createElement('DIV');
        outerDiv.appendChild(innerDiv);

        outerDiv.className = defaults.className;
        outerDiv.style.width = '100%';
        // 4:3
        outerDiv.style.paddingTop = parseFloat(100/defaults.ratio) + '%';
        outerDiv.style.backgroundColor = defaults.backgroundColor;
        outerDiv.style.position = 'relative';

        innerDiv.style.position = 'absolute';
        innerDiv.style.left = '0';
        innerDiv.style.top = '0';
        innerDiv.style.width = '100%';
        innerDiv.style.height = '100%';

        innerDiv.innerHTML = '<p>'+defaults.html+'</p>';
        innerDiv.style.color = defaults.color;
        innerDiv.style.display = 'flex';
        innerDiv.style.flexDirection = 'column';
        innerDiv.style.alignItems = 'center';
        innerDiv.style.textAlign = 'center';
        innerDiv.style.justifyContent = 'center';

        var button1 = document.createElement('button');
        button1.className = defaults.button1Classes;
        button1.innerHTML = defaults.button1;

        var button2 = document.createElement('button');
        button2.innerHTML = defaults.button2;
        button2.className = defaults.button2Classes;

        innerDiv.append(button1);
        innerDiv.append(button2);

        button1.addEventListener("click", function () {
            showIFrame(iFrame);
            outerDiv.style.display = 'none';
        });

        button2.addEventListener('click',function() {
            setCookie(defaults.cookieName,true);
            fragments.map(function(item) {
                item.parentElement.removeChild(item);
            });
            for (var i = 0; i < iFrames.length; i++) {
                var iFrame = iFrames[i];
                showIFrame(iFrame);
            }
        });
        return outerDiv;
    }

    function isYoutube(src) {
        if(src){
            return src.match(/(youtu\.be|youtube\.com|youtube-nocookie\.com)/);
        }
        return false;
    }

// BARRIEREFREIE TOOLBAR
    $('.toggle-grayscale').on('click', function(){
        if ((sessionStorage.getItem('fGrayscale') === "1") && ($('body').hasClass("grayscalestyle"))) {
            $('body').removeClass("grayscalestyle");
            sessionStorage.setItem('fGrayscale', "0");
        }else{
            $('body').addClass('grayscalestyle');
            sessionStorage.setItem('fGrayscale', "1");
        }
    });

    $('.toggle-contrast').on('click', function(){
        if ((sessionStorage.getItem('fContrast') === "1") && ($('body').hasClass("contraststyle"))) {
            $('body').removeClass("contraststyle");
            sessionStorage.setItem('fContrast', "0");
        }else{
            $('body').addClass('contraststyle');
            sessionStorage.setItem('fContrast', "1");
        }
    });

    $('.up-fontsize').on('click', function(){
        var actualfontsize = sessionStorage.getItem('fFontsize');
        if(sessionStorage.getItem('fFontsize') === null) {actualfontsize = "100";}

        if (actualfontsize < "150"){
            actualfontsize = parseInt(actualfontsize) + 10;
            $('html').css('font-size', actualfontsize+'%');
            sessionStorage.setItem('fFontsize', actualfontsize);
        }
    });
    $('.down-fontsize').on('click', function(){
        var actualfontsize = sessionStorage.getItem('fFontsize');
        if(sessionStorage.getItem('fFontsize') === null) {actualfontsize = "100";}

        if (actualfontsize > "100"){
            actualfontsize = parseInt(actualfontsize) - 10;
            $('html').css('font-size', actualfontsize+'%');
            sessionStorage.setItem('fFontsize', actualfontsize);
        }
    });

    //ONLOAD CHECK
    var actualfontsize = sessionStorage.getItem('fFontsize') || 100;
    $('html').css('font-size', actualfontsize+'%');


    if (sessionStorage.getItem('fGrayscale') === "1") {
        $('body').addClass('grayscalestyle');
    }

    if (sessionStorage.getItem('fContrast') === "1") {
        $('body').addClass('contraststyle');
    }
});
